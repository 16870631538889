#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow:
    483px 450px #fff,
    1995px 525px #fff,
    755px 1459px #fff,
    610px 1246px #fff,
    764px 1850px #fff,
    1274px 1697px #fff,
    1227px 37px #fff,
    1090px 506px #fff,
    341px 1005px #fff,
    1002px 755px #fff,
    693px 1497px #fff,
    1797px 1626px #fff,
    1838px 793px #fff,
    802px 881px #fff,
    1117px 1983px #fff,
    352px 1465px #fff,
    1526px 852px #fff,
    1559px 526px #fff,
    517px 516px #fff,
    1380px 244px #fff,
    662px 1531px #fff,
    1544px 518px #fff,
    253px 879px #fff,
    774px 1629px #fff,
    752px 994px #fff,
    1129px 726px #fff,
    232px 1050px #fff,
    1766px 290px #fff,
    1976px 842px #fff,
    662px 1535px #fff,
    257px 1016px #fff,
    665px 708px #fff,
    1476px 1772px #fff,
    261px 498px #fff,
    719px 1459px #fff,
    469px 438px #fff,
    994px 474px #fff,
    744px 808px #fff,
    1609px 1741px #fff,
    898px 260px #fff,
    1978px 1436px #fff,
    904px 1244px #fff,
    123px 773px #fff,
    6px 923px #fff,
    1973px 1778px #fff,
    1470px 1861px #fff,
    1037px 156px #fff,
    554px 1905px #fff,
    1508px 849px #fff,
    1213px 1603px #fff,
    87px 1907px #fff,
    492px 527px #fff,
    91px 213px #fff,
    492px 15px #fff,
    1357px 1157px #fff,
    637px 240px #fff,
    1515px 281px #fff,
    1757px 682px #fff,
    1035px 1298px #fff,
    1019px 1233px #fff,
    25px 162px #fff,
    262px 884px #fff,
    1328px 965px #fff,
    984px 879px #fff,
    1213px 689px #fff,
    623px 1091px #fff,
    1227px 422px #fff,
    1977px 1025px #fff,
    620px 474px #fff,
    528px 1525px #fff;
  animation: animStar 10s linear infinite;
}

#stars:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow:
    483px 450px #fff,
    1995px 525px #fff,
    755px 1459px #fff,
    610px 1246px #fff,
    764px 1850px #fff,
    1274px 1697px #fff,
    1227px 37px #fff,
    1090px 506px #fff,
    341px 1005px #fff,
    1002px 755px #fff,
    693px 1497px #fff,
    1797px 1626px #fff,
    1838px 793px #fff,
    802px 881px #fff,
    1117px 1983px #fff,
    352px 1465px #fff,
    1526px 852px #fff,
    1559px 526px #fff,
    517px 516px #fff,
    1380px 244px #fff,
    662px 1531px #fff,
    1544px 518px #fff,
    253px 879px #fff,
    774px 1629px #fff,
    752px 994px #fff,
    1129px 726px #fff,
    232px 1050px #fff,
    1766px 290px #fff,
    1976px 842px #fff,
    662px 1535px #fff,
    257px 1016px #fff,
    665px 708px #fff,
    1476px 1772px #fff,
    261px 498px #fff,
    719px 1459px #fff,
    469px 438px #fff,
    994px 474px #fff,
    744px 808px #fff,
    1609px 1741px #fff,
    898px 260px #fff,
    1978px 1436px #fff,
    904px 1244px #fff,
    123px 773px #fff,
    6px 923px #fff,
    1973px 1778px #fff,
    1470px 1861px #fff,
    1037px 156px #fff,
    554px 1905px #fff,
    1508px 849px #fff,
    1213px 1603px #fff,
    87px 1907px #fff,
    492px 527px #fff,
    91px 213px #fff,
    492px 15px #fff,
    1357px 1157px #fff,
    637px 240px #fff,
    1515px 281px #fff,
    1757px 682px #fff,
    1035px 1298px #fff,
    1019px 1233px #fff,
    25px 162px #fff,
    262px 884px #fff,
    1328px 965px #fff,
    984px 879px #fff,
    1213px 689px #fff,
    623px 1091px #fff,
    1227px 422px #fff,
    1977px 1025px #fff,
    620px 474px #fff,
    528px 1525px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow:
    1585px 315px #fff,
    1750px 858px #fff,
    502px 1184px #fff,
    1482px 150px #fff,
    870px 93px #fff,
    1401px 1976px #fff,
    494px 1549px #fff,
    1607px 1894px #fff,
    1029px 1466px #fff,
    1931px 1390px #fff,
    819px 1431px #fff,
    521px 1062px #fff,
    1754px 574px #fff,
    577px 943px #fff,
    850px 1377px #fff,
    445px 1835px #fff,
    1658px 1675px #fff,
    521px 1144px #fff,
    560px 1624px #fff,
    1329px 1778px #fff;
  animation: animStar 20s linear infinite;
}

#stars2:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow:
    1585px 315px #fff,
    1750px 858px #fff,
    502px 1184px #fff,
    1482px 150px #fff,
    870px 93px #fff,
    1401px 1976px #fff,
    494px 1549px #fff,
    1607px 1894px #fff,
    1029px 1466px #fff,
    1931px 1390px #fff,
    819px 1431px #fff,
    521px 1062px #fff,
    1754px 574px #fff,
    577px 943px #fff,
    850px 1377px #fff,
    445px 1835px #fff,
    1658px 1675px #fff,
    521px 1144px #fff,
    560px 1624px #fff,
    1329px 1778px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow:
    91px 409px #fff,
    1886px 728px #fff,
    1294px 970px #fff,
    720px 1215px #fff,
    796px 1431px #fff,
    834px 1217px #fff,
    1926px 1164px #fff,
    1803px 310px #fff,
    1532px 1421px #fff,
    1680px 232px #fff;
  animation: animStar 30s linear infinite;
}

#stars3:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow:
    91px 409px #fff,
    1886px 728px #fff,
    1294px 970px #fff,
    720px 1215px #fff,
    796px 1431px #fff,
    834px 1217px #fff,
    1926px 1164px #fff,
    1803px 310px #fff,
    1532px 1421px #fff,
    1680px 232px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }

  50% {
    transform: translateX(-15px);
  }

  75% {
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0);
  }
}

@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
  margin: 0;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 48px;
}

p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #333333;
}

// .header {
//   position: relative;
//   text-align: center;
//   background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
//   color: white;
// }

.logo {
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
}

.inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex {
  /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.box {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px 50px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
  font: 16px/24px Arial, sans-serif;
  background-color: #4973ff;
  transition:
    box-shadow 0.4s ease,
    background-color 0.4s ease,
    color 0.4s ease;
  box-shadow:
    0 0 2px 0 rgba(73, 115, 255, .1),
    0 0 4px 0 rgba(73, 115, 255, .2),
    0 0 6px 0 rgba(73, 115, 255, .3),
    0 0 8px 0 rgba(73, 115, 255, .4),
    0 0 12px 0 rgba(73, 115, 255, .5),
    0 0 18px 0 rgba(73, 115, 255, .6);
}

.box:hover {
  background-color: #ea3c;
  box-shadow:
    0 0 2px 0 rgba(238, 170, 51, 0.1),
    0 0 4px 0 rgba(238, 170, 51, 0.2),
    0 0 6px 0 rgba(238, 170, 51, 0.3),
    0 0 8px 0 rgba(238, 170, 51, 0.4),
    0 0 12px 0 rgba(238, 170, 51, 0.5),
    0 0 18px 0 rgba(238, 170, 51, 0.6),
    0 0 4px 0 rgba(238, 170, 51, 0.7);
}

.box span {
  color: #fff;
  letter-spacing: 8px;
}

.box i {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 200px;
  background-color: inherit;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
  transition:
    transform 0.4s linear,
    top 1s linear;
  overflow: hidden;
}

.box i:before,
.box i:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
}

.box i:before {
  border-radius: 46%;
  background-color: rgba(20, 20, 20, 0.2);
  animation: animate 5s linear infinite;
}

.box i:after {
  border-radius: 40%;
  background-color: rgba(20, 20, 20, 0.5);
  animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform:
      translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform:
      translate(-50%, -75%) rotate(360deg);
  }
}

.eis-line {
  position: relative;
  width: 100%;
  height: 2px;
}

.eis-line div {
  position: absolute;
  width: 100px;
  /* Length of each segment */
  height: 2px;
  /* Thickness of the line */
  background-color: white;
  /* Line color */
  transform-origin: left center;
}

.eis-line div::before {
  content: '';
  position: absolute;
  width: 15px;
  /* Circle diameter */
  height: 15px;
  background-color: white;
  /* Circle color */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 0;
  /* Start of the segment */
}

.eis-line div::after {
  content: '';
  position: absolute;
  width: 15px;
  /* Circle diameter */
  height: 15px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  right: -7.5px;
  /* Center the circle horizontally */
  transform: translateY(-50%);
}

.eis-line-mask {
  overflow: hidden;
  position: relative;
  width: 200px;
  height: 200px;
  // background-image: url('your-image.jpg');
  /* Replace with your background image */
  background-size: cover;
  background-position: center;
  mask-image: radial-gradient(circle 300px, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-image: radial-gradient(circle 300px, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  z-index: 0;
}

.droplet {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 0 50% 50% 50%;
  background-color: #B6B6B6;
  transform: rotate(45deg);
  margin-top: 10px;
  pointer-events: none;
  animation: growAndFall 1.5s ease-in forwards;
}

@keyframes growAndFall {
  0% {}

  100% {
    transform: translateY(100vh) rotate(45deg);
  }
}