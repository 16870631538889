html {
  overflow-y: auto;
}

:root {
  --dark-1: #373a3d;
}

.block {
  width: 100%;
  height: 100%;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23AEAEAEFF' stroke-width='3' stroke-dasharray='2%25%2c4%25' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.027);
}

.scroll-container {
  scrollbar-width: none;
  /* For Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, Edge */
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl('padding-right', var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.header>.container-fluid,
.sidebar-header {
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.sidebar-brand-full {
  margin-left: 0px;
  --cui-sidebar-header-padding-y: 100px;
}

.selected-box {}

.selected-box-pusher {
  display: inline-block;
  width: 50;
}

@keyframes swoop {
  0% {
    margin-left: 0%;
  }

  100% {
    margin-left: -130%;
  }
}

.dropdown-item {
  opacity: 1;
}

/* General Dropdown */
#dropdown {
  color: #E4E6EB;
  border: 1px solid #4B4E51;
  background-color: #2e3135;
  // outline: 1px solid #4B4E51;
}

/* Dropdown Items */
#dropdown .dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: #2e3135;
}

// /* Even Items */
// #dropdown .dropdown-item:nth-child(even) {
//   background-color: #373A3D;
// }

// /* Odd Items */
// #dropdown .dropdown-item:nth-child(odd) {
//   background-color: #2B2E31;
// }

/* Hover Effect */
#dropdown .dropdown-item:hover {
  background-color: #272A2D;
  color: #FFFFFF;
  /* Brighter text on hover */
}

.hover-item:hover {
  background-color: #272A2D;
  color: #FFFFFF;
  /* Brighter text on hover */
}

.click {
  background-color: $primary;
  color: #FFFFFF;
  /* Brighter text on hover */
}


.swoop-notification {
  animation: swoop 1s;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-right {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 100%;
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.sidebar-toggler {
  @include ltr-rtl('margin-left', auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl('margin-right', auto);
  }
}

.header>.container-fluid+.container-fluid {
  min-height: 3rem;
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}

.search {
  background-color: $input-bg-search;
}

.form-control:focus {
  background-color: $input-bg-search;
  //box-shadow:     0 0 3px 0px #000000,  /* inner white */
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-highlight {
  border: solid;
  border-color: white;
  transition: 0s;
  border-width: 2px;
  opacity: 1;
}

.text {
  border: solid;
  border-color: $fresh-red;
  transition: 0s;
  border-width: 2px;
  opacity: 0;
}

.visible {
  transition: max-height 0s linear;
  max-height: 500px;
  overflow: hidden;
}

.hidden {
  transition: max-height 0s linear;
  max-height: 0;
  overflow: hidden;
}

.triangle-showing {
  rotate: 180deg;
  transition: rotate 0.1s;
}

.triangle-hiding {
  rotate: 90deg;
  transition: rotate 0.1s;
}

.text:hover {
  opacity: 1;
  //background-color: firebrick;
  transition: 0.1s;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.calendar-button {
  background-color: #373a3d;
}

.calendar-button:hover {
  background-color: mix(#373a3d, #888);
}

.calendar-button:disabled {
  background-color: #373a3d;
}

.highlight-first {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.highlight-last {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.highlight-first-selected {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.highlight-last-selected {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.bg-subtle-primary:hover {
  background-color: rgba(164, 31, 19, 0.4);
}

.bg-primary-25 {
  background-color: rgba(164, 31, 19, 0.1);
}

.glow-effect {
  -webkit-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  -moz-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  box-shadow: 0px 0px 30px 3px rgba(255, 46, 46, 0.21);
  transition: box-shadow 0.1s;
}

.glow-effect:hover {
  -webkit-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  -moz-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  transition: box-shadow 0.1s;
}

.upload:hover {
  transform: scale(1.03);
  transition: transform 0.1s;
}

.upload {
  transform: scale(1);
  transition: transform 0.1s;
}

.hovercard {
  outline: none;
  transform: scale(1);
  transition: transform 0.1s;
}

.hovercard:hover {
  transform: scale(1.01);
  transition: transform 0.1s;
}

.meta-hidden {
  max-height: 0;
  transition: max-height 1s;
}

.meta-visible {
  max-height: fit-content;
  transition: max-height 1s;
}

.notification-cross {
  display: none;
  cursor: pointer;
}

.notification-box {
  background-color: $dark;
  cursor: pointer;
}

.notification-box:hover .notification-cross {
  display: block;
}

.notification-box:hover {
  // background-color: mix($dark, $primary);
  background-color: #272A2D;
}

.project-tile {
  background-color: rgba(255, 255, 255, 0.027);
  transition: background-color 0.3s linear;
}

.project-tile:hover {
  //scale: 1.05;
  background-color: #9b271c;
}

.wave {
  --size: 20px;
  --m: 0.5;
  --b: 2px;
  --p: calc(var(--m) * var(--size));
  --R: calc(var(--size) * sqrt(var(--m) * var(--m) + 1) + var(--b) / 2);

  //animation: animWave 1s alternate infinite;

  --_g: #0000 calc(99% - var(--b)), #000 calc(101% - var(--b)) 99%, #0000 101%;
  mask:
    radial-gradient(var(--R) at left 50% bottom calc(-1 * var(--p)), var(--_g)) calc(50% - 2 * var(--size)) calc(50% - var(--size) / 2 - var(--b) / 2) / calc(4 * var(--size)) calc(var(--size) + var(--b)) repeat-x,
    radial-gradient(var(--R) at left 50% top calc(-1 * var(--p)), var(--_g)) 50% calc(50% + var(--size) / 2 + var(--b) / 2) / calc(4 * var(--size)) calc(var(--size) + var(--b)) repeat-x;
}

@keyframes animWave {
  0% {
    --m: 0;
  }

  50% {
    --m: 0.5;
  }

  100% {
    --m: 1;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.popup-warning {
  margin-top: -40px;
  transition: margin-top 0.1s linear;
}

.popup-warning-showing {
  margin-top: -10px;
  transition: margin-top 0.1s linear;
}

.sidebar-button>.nav-link {
  background-color: $primary;
  transition: all 2s;
}

.sidebar-button:hover>.nav-link {
  background-color: mix($dark, $primary);
  transition: all 2s;
}

.sidebar-button:hover {
  //margin-left: 5px;
}

.app-tile {
  background-color: #2e3135;
}

.app-tile:hover {
  background-color: mix($primary, $dark-1);
}

.graph-tile {
  background-color: #52100a;
}

.bg-tile {
  background-color: #fff;
}

.bg-tertiary {
  background-color: #373a3d;
}

.bg-test {
  background-color: #fff;
}

body {
  background-color: $light-gray;
}

.bg-body {
  background-color: $light-gray;
}

.bg-color1 {
  background-color: #f1f3f4;
}

.bg-color2 {
  background-color: $light-gray;
}

.bg-login-menu {
  background-color: #D6D8D8;
}

.blur {
  filter: blur(5px);
  transition: filter 0.3s ease;
}

.bg-header {
  background-color: rgba(red($light-gray), green($light-gray), blue($light-gray), 0.8);
}

.bg-chart {
  background-color: rgba(46, 49, 53, 1);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@keyframes fadeOutScale {
  0% {
    background-color: $primary;
  }

  100% {
    background-color: #373a3d;
  }
}

.animate-tag-refused-once {
  animation: fadeOutScale 0.5s cubic-bezier(1, 0, 1, 0.7);
  animation-fill-mode: both;
}

.upload-file-button-drop-files {
  color: #ccc;
}

.upload-file-button-choose-files {
  color: #ccc;
}

.upload-file-button-choose-files:hover {
  color: #fff;
}

.upload-box-border {
  border: 2px dashed #555;
}

.upload-box-border-hover {
  border: 2px dashed mix(#555, $primary);
  background-color: rgba(mix(#555, $primary), 0.1);
}

.file-tile {}


.tile-hover-box {
  background-color: rgba(0, 0, 0, 0.3); // Semi-transparent background
  backdrop-filter: blur(2px); // Blur the underlying elements
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
}

.file-tile:hover {
  // background-color: rgba(mix(#555, $primary), 0.3);
  border: 2px solid mix(#555, $primary);
}

.text-area {
  // outline: 1px solid $dark-1;
  // transition: outline 0.2s ease-in-out;
  outline: 0px solid $dark-1;
}

.text-area:focus {
  // outline: 1px solid $light;
  // transition: outline 0.1s ease-in-out;
}

#journal {
  direction: ltr; // Ensure left-to-right text direction
  cursor: text; // Change cursor to text when hovering over the editable area
}

#journal pre {
  color: #fff;
  font: 16px/1.5 monospace; // Set font size and line height for code blocks
}

.resizable-box {
  resize: both; // Allow resizing in both directions
  overflow: hidden; // Ensure the resize handle is visible
  display: inline-block; // Ensure the box is treated as an inline block element
  border: 1px dashed #ccc; // Optional: Add a border to the resizable box
  padding: 0; // Remove padding to ensure the image fits perfectly
  cursor: pointer;
  user-select: none; // Prevent text selection inside the resizable box
  padding: 10px;
}

.resizable-box img {
  width: 100%; // Make the image fill the width of the resizable box
  height: 100%; // Make the image fill the height of the resizable box
  object-fit: cover; // Ensure the image covers the box while maintaining aspect ratio
  pointer-events: none; // Prevent the cursor from interacting with the image
}

#journal-preview p {
  color: #e4e4e5;
  font-size: medium;
  letter-spacing: 0px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#journal #journal-preview ul {
  margin-left: 20px;
}

// Change the color of the table line to white
#journal-preview table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 0px solid #fff;
}

//Change the color of the division bottom line of the header in the table to white
#journal-preview th {
  border-bottom: 1px solid #777;
  padding: 8px;
  text-align: left;
  color: #fff;
}

//Change the color of the division right line of the table to white unless it is the most outside line
#journal-preview td {
  border-right: 1px solid #777;
  border-left: 1px solid #777;
  padding: 8px;
  text-align: left;
  color: #fff;
}

#journal-preview .user-tag {
  color: #ccc;
  //Disable the underline of the link
  text-decoration: none;
  // Thin font
  font-weight: 300;
  // Rounded corners
  border-radius: 5px;
  // Padding around the link
  padding: 5px;
  // Solid background color
  background-color: $primary;
  // Inverse font color
  color: #fff;
  // Inline
  display: inline;
}

#journal-preview .measurement-tag {
  color: #ccc;
  //Disable the underline of the link
  text-decoration: none;
  // Thin font
  font-weight: 300;
  // Rounded corners
  border-radius: 5px;
  // Padding around the link
  padding: 5px;
  // Solid background color
  background-color: $secondary;
  // Inverse font color
  color: #fff;
  // Inline
  display: inline;
}

.journal-menu-option {
  background-color: $dark-1;
  cursor: pointer;
}

.journal-menu-option:hover {
  background-color: mix($dark-1, $primary);
}

//When button is pressed, color is primary
.journal-menu-option:active {
  background-color: $primary;
}

.journal-menu-option-selected {
  background-color: $primary;
  cursor: pointer;
}

// .tooltip {
//   position: relative;
//   aspect-ratio: '1 / 1';
//   height: '100%';
//   margin-right: '-5px';
// }

// .tooltip .tooltip-text {
//   z-index: 300;
//   left: '50%';
//   top: -25;
//   transform: 'translate(-50%, 0%)';
// }

.tooltip-text {
  transition: opacity 0.1s ease-in-out;
}

#journal-syntax,
#journal {
  white-space: pre-wrap;
  /* Enable word wrapping */
  word-wrap: break-word;
  /* Break long words */
}

#journal-preview li {
  margin-left: 20px;
}

.bg-dark-2 {
  background-color: mix($dark-1, #000);
}

.slider-rail {
  background-color: #444;
  transition: background-color 0.2s;
}

.slider-box:hover>.slider-rail {
  // background-color: #fff;
}

.slider-knob-text {
  opacity: 0;
  transition: opacity 0.2s;
}

.slider-knob:hover>.slider-knob-text {
  opacity: 1;
}

.image-button {
  scale: 1;
  transition: scale 0.2s;
}

.image-button:hover {
  scale: 1.05;
}

.gradient-background {
  background: linear-gradient(135deg, $primary, mix($primary, $dark));
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  //Blur background
  backdrop-filter: blur(5px);
  /* Dark overlay */
  transition: opacity 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
}


.loading-icon {
  color: $primary;
  animation: bouncy-rotate 1.5s ease-in-out infinite;
}

@keyframes bouncy-rotate {
  0% {
    transform: rotate(0deg);
    /* Start */
  }

  10% {
    transform: rotate(-20deg);
    /* Backup slightly */
  }

  60% {
    transform: rotate(380deg);
    /* Overshoot */
  }

  70% {
    transform: rotate(340deg);
    /* Slight counter rotation */
  }

  80% {
    transform: rotate(360deg);
    /* End at full rotation */
  }

  100% {
    transform: rotate(360deg);
    /* End at full rotation */
  }
}

.app-tile:hover> {
  .app-tile-icon {
    zoom: 1.3;
    transition: zoom 0.2s;
  }

  .app-tile-center {
    opacity: 1;
    transition: opacity 0.2s;
  }
}

.ghost-tile {
  background: linear-gradient(135deg, $dark-1, $dark);
  background-size: 200% 200%; // Ensure the background is large enough to animate
  animation: ghost-moving-background 1s infinite;
}

@keyframes ghost-moving-background {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0 0;
  }


}

.fade-in-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out.show {
  opacity: 1;
}

.bg-table-row-even {
  background-color: #373a3d;
}

.bg-table-row-even:hover {
  background-color: mix(#373a3d, #666);
}

.bg-table-row-odd {
  background-color: #2e3135;
}

.bg-table-row-odd:hover {
  background-color: mix(#2e3135, #666);
}

@keyframes overshoot-slide {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  80% {
    transform: translateY(-5px);
    /* Slight overshoot above */
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

.overshoot-slide {
  animation: overshoot-slide 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  animation-fill-mode: both;
}

.fade-shrink {
  // opacity: 0;
  transform: scale(0.9);
  transition: all 1s;
}

.animated-button {
  opacity: 1;
  transform: translateX(0px); // Start off-screen to the right
  // animation: slideInRight 0.3s ease-out forwards;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    // transform: translateX(50px);
  }

  100% {
    opacity: 1;
    // transform: translateX(0);
  }
}