//
//
// DARK MODE DOWN BELOW
//
//
@if $enable-dark-mode {
  @include color-mode(dark) {
    body {
      background-color: var(--cui-dark-bg-subtle);
    }

    .bg-body {
      background-color: var(--cui-dark-bg-subtle);
    }

    .header {
      background-color: $header-bg-dark;
    }

    .footer {
      --cui-footer-bg: var(--cui-body-bg);
    }

    .bg-test {
      background-color: $dark-1;
    }

    .bg-tile {
      background-color: $dark-1;
    }

    .bg-dark-2 {
      background-color: mix($dark-1, #1f1f1f);
    }

    .bg-color1 {
      background-color: var(--cui-dark-bg-subtle);
    }

    .bg-color2 {
      background-color: var(--cui-dark-bg-subtle);
    }

    .bg-login-menu {
      background-color: #4f4f4f
    }

    .bg-header {
      background-color: rgba(46, 49, 53, 0.8);
    }

    .bg-chart {
      background-color: rgba(255, 255, 255, 1);
    }
  }

}