/* Base Tokens */

/* Bold Text */
.token.bold {
  font-weight: bold;
  color: #ffb86c;
  /* Amber for bold text */
}

/* Italic Text */
.token.italic {
  font-style: italic;
  color: #ff9e9e;
  /* Soft pink-red for italic text */
}

/* Plain Text */
.token.plain {
  color: #e5e5e5;
  /* Neutral light gray for plain text */
}

/* Pipes `|` */
.token.keyword {
  color: #ff6666;
  /* Dark red for pipes */
  font-weight: bold;
}

/* Headers */
.token.document-header {
  color: #f56565;
  /* Medium dark red for headers */
  font-weight: bold;
}

/* Separator Line (`---`) */
.token.operator {
  color: #d65d0e;
  /* Burnt orange for separator lines */
  font-style: normal;
}

/* Alignment Markers (`:---`, `---:`, `:---:`) */
.token.function {
  color: #ffa726;
  /* Amber for alignment markers */
  font-style: italic;
}

/* Table Content */
.token.table-content {
  color: #b2b2b2;
  /* Muted gray for table content */
  font-style: normal;
}

/* User-Defined Tags */
.token.user-tag {
  color: #ffb86c;
}

/* Custom Experience Tags */
.token.exp-tag {
  color: #f56565;
  /* Medium dark red for experience tags */
  font-weight: bold;
}

/* LaTeX Inline */
.token.latex-inline {
  color: #ff9e57;
  /* Amber-orange for LaTeX inline */
  font-style: italic;
}

/* LaTeX Block */
.token.latex-block {
  color: #d65d0e;
  /* Burnt orange for LaTeX block */
}

/* Document Bold */
.token.document-bold {
  color: #ffb86c;
  /* Amber for bold text */
  font-weight: bold;
}

/* Document Italic */
.token.document-italic {
  color: #ff9e9e;
  /* Soft pink-red for italic text */
  font-style: italic;
}

/* Document Keyword */
.token.document-keyword {
  color: #ff6666;
  /* Dark red for keywords */
}

/* Document Number */
.token.document-number {
  color: #ffa726;
  /* Amber for numbers */
}

/* Document String */
.token.document-string {
  color: #ffcc80;
  /* Soft amber-yellow for strings */
}

/* Document Comment */
.token.document-comment {
  color: #928374;
  /* Muted gray for comments */
}

/* Image Number Braces */
.token.image-notation .token.keyword {
  color: #d65d0e;
  /* Burnt orange for braces in image-notation */
}

/* Brackets in Image Description */
.token.image-notation .token.image-description .token.keyword {
  color: #d65d0e;
  /* Burnt orange for braces in image-description */
}

/* Brackets in Image Size */
.token.image-notation .token.image-size .token.keyword {
  color: #d65d0e;
  /* Burnt orange for brackets in image-size */
}