// Variable overrides
//
// If you want to customize your project please add your variables below.

$enable-deprecation-messages: false !default;
$enable-dark-mode: true;

$fresh-red: #a41f13;
$white-fog: #faf5f1;

//Used for neutral backgrounds
$light-gray: #F1F3F4;
$carbon-gray: #292f36;
$soft-brown: #8f7a6e;

$dark-1: #373a3d;

//
//
//
$primary: #972e1a;
$secondary: #4f535c;
$success: #2eb85c;
$info: $fresh-red;
$warning: rgb(251, 205, 0);
$danger: #ff9900;
$light: #b6b6b6;
$dark: mix($carbon-gray, #333);

//
//
//
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);